import React, {useEffect} from "react";
import { useState } from "react";
import ImageGift from "../../assets/img/gift.svg";
import InvoiceDataForm, { LEGAL_FIELDS, CLIENT_ENTITY_TYPE, PHYSICAL_FIELDS } from "./InvoiceDataForm";

export default function SubscriptionPayment(props) {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isInvoiceNeeded, setIsInvoiceNeeded] = useState(false);
  const [hasTermsError, setHasTermsError] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [invoiceData, setInvoiceData] = useState({
    entity_type: CLIENT_ENTITY_TYPE.physical
  });
  const [isInvoiceDataValid, setIsInvoiceDataValid] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [isErrorsVisible, setIsErrorsVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isTermsAccepted) setHasTermsError(false)
  }, [isTermsAccepted]);

  useEffect(() => {
    const valid = validate();

    setIsValid(valid);
  }, [
    isTermsAccepted,
    isInvoiceNeeded,
    hasTermsError,
    discountCode,
    invoiceData,
    isInvoiceDataValid,
  ]);

  function validate() {
    if (isInvoiceNeeded && !isInvoiceDataValid) return false;

    return isTermsAccepted;
  }

  function showErrors() {
    if (!isTermsAccepted) {
      setHasTermsError(true);
    }

    setIsErrorsVisible(true);
  }

  const handlePlanPayment = () => {
    if (!isValid) {
      showErrors();
      return;
    }

    window.sys.track(`pricing_${props.plan.key.toLowerCase()}_pay.click`, props.activityId);

    const formData = new FormData();
    formData.append('plan_id', props.plan.key);

    if (props.plan.activity_dropdown) {
      formData.append('activity_id', props.activityId);
    }

    if (props.plan.discount_code) {
      formData.append('discount', props.plan.discount_code.code);
    }

    if (isInvoiceNeeded) {
      formData.append('send_invoice', 1);
      formData.append('entity_type', invoiceData['entity_type']);

      if (invoiceData['entity_type'] === CLIENT_ENTITY_TYPE.physical) {
        PHYSICAL_FIELDS.forEach(field => {
          formData.append(field.name, invoiceData[field.name] || '');
        });
      }

      if (invoiceData['entity_type'] === CLIENT_ENTITY_TYPE.legal) {
        LEGAL_FIELDS.forEach(field => {
          formData.append(field.name, invoiceData[field.name] || '');
        });
      }
    }

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = process.env.REACT_APP_SITE_URL + 'opay/payment';

    formData.forEach((value, key) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  }

  return (
    <React.Fragment>
      <div className={`SubscriptionPayment container mt-5`} style={{ maxWidth: "860px" }}>
        <h2>Jūsų užsakymas</h2>
        <p className="mt-3">Mokėjimo būdo pasirinkimas</p>

        <div className="row card p-3 p-sm-5 mt-5">
          <table className="table">
            <thead>
            <tr className="summary-box-header">
              <th className="text-left border-top-0">Pavadinimas</th>
              <th className="border-top-0">Kiekis</th>
              <th className="border-top-0" style={{width: "110px"}}>Kaina</th>
            </tr>
            </thead>
            <tbody>
            <tr className="summary-box-item">
              <td className="text-left">Ataskaitų prenumerata</td>
              <td>{props.plan.title}</td>
              <td className="text-right">
                {props.plan.discounted && <>
                  <span className="text-danger"
                        style={{textDecoration: "line-through"}}>{props.plan.price_monthly_original / 100}&nbsp;€</span>
                  {" "}
                </>}
                {props.plan.price_monthly / 100}&nbsp;€
              </td>
            </tr>
            {props.plan.discount_code && <>
              <tr className="summary-box-item font-weight-bold">
                <td className="text-left" colSpan={2}>Viso</td>
                <td className="text-right">{props.plan.price / 100}&nbsp;€</td>
              </tr>
              <tr className="summary-box-item text-success">
                <td className="text-left" colSpan={2}>Nuolaidos kodas
                  (<b>{props.plan.discount_code.code.toUpperCase()}</b>)
                </td>
                {props.plan.discount_code.type === 'pct' ?
                  <td className="text-right">-{props.plan.discount_code.value}&nbsp;%</td>
                  :
                  <td className="text-right">-{props.plan.discount_code.value / 100}&nbsp;€</td>
                }
              </tr>
            </>
            }
            <tr className="summary-box-total">
              <td className="text-left" colSpan={2}>Mokėtina suma</td>
              <td className="text-right">{props.plan.final_price / 100}&nbsp;€</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div className="row p-3">
          <div className="col-12 px-0 mx-3 text-left">
            <div className="row">
              <label className="field-container">
                <input type="checkbox" onChange={(e) => setIsInvoiceNeeded(e.target.checked)} />
                <span>Noriu gauti PVM sąskaitą faktūrą</span>
              </label>
            </div>
          </div>
        </div>
        <div className={`row card p-3 p-sm-5 ${isInvoiceNeeded ? 'd-block' : 'd-none'}`}>
          <div className="text-left">
            <InvoiceDataForm {...{
              invoiceData,
              setInvoiceData,
              setIsInvoiceDataValid,
              isErrorsVisible
            }}
            />
          </div>
        </div>

        <div className="row card p-3 p-sm-5 mt-5">
          <div className="text-left">
            <h6>
              <img src={ImageGift} alt="Gift"/>
              <span className="align-bottom ml-1">Turite nuolaidos kodą?</span>
            </h6>
            <p className="payment-box-subtitle">Pridėkite kodą ir gaukite nuolaidą</p>
          </div>
          <div className="border-bottom"></div>

          <form className="form-inline mt-3">
            <div className="form-group mb-0 mr-2 mt-2 mt-sm-0">
              <input
                type="text"
                className="form-control"
                id="inputCode"
                placeholder="Įveskite kodą"
                onChange={(e) => setDiscountCode(e.target.value)}
                value={discountCode}
              />
            </div>
            <button type="submit" className="btn btn-primary mt-2 mt-sm-0"
                    disabled={discountCode === ""}
                    onClick={(e) => {
                      e.preventDefault();
                      props.handleDiscount(discountCode);
                      setDiscountCode("");
                    }}
            >Įvesti
            </button>
          </form>
        </div>

        <div className="row p-3 p-sm-5">
          <div className="col-12 text-center">
            <label className="field-container">
              <input type="checkbox" onChange={(e) => setIsTermsAccepted(e.target.checked)}/>
              <span>
                Sutinku su UNTU <a href="/terms-and-conditions" target="_blanks">bendrosiomis taisyklėmis</a>.
              </span>
              {isErrorsVisible && hasTermsError &&
                <p className="text-danger text-left" style={{ marginLeft: '17px' }}>Pažymėkite, kad sutinkate su taisyklėmis.</p>}
            </label>
          </div>

          <div className="col-12 text-center mt-3">
            <button className="btn btn-lg btn-primary"
              onClick={() => handlePlanPayment()}
            >Sumokėti</button>

            {isErrorsVisible && !isValid && (
              <div className="text-danger mt-3">Užpildykite privalomus laukus</div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
