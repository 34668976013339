import React, { useEffect, useRef, useState } from "react";

export const CLIENT_ENTITY_TYPE = {
  physical: "private",
  legal: "legal",
};

export const PHYSICAL_FIELDS = [
  { name: 'entity_name', required: true },
  { name: 'address', required: true },
  { name: 'entity_code', required: true },
  { name: 'vat_code', required: false },
]

export const LEGAL_FIELDS = [
  { name: 'entity_name', required: true },
  { name: 'address', required: true },
  { name: 'entity_code', required: true },
  { name: 'vat_code', required: false },
]

export default function InvoiceDataForm(props) {
  const {
    invoiceData,
    setInvoiceData,
    setIsInvoiceDataValid,
    isErrorsVisible,
  } = props;

  const [unvalidFields, setUnvalidFields] = useState({});
  const unvalidFieldsRef = useRef({});

  function onChange(event) {
    setInvoiceData({
      ...invoiceData,
      [event.target.name]: event.target.value,
    });
  }

  useEffect(() => {
    setUnvalidFields({});
    unvalidFieldsRef.current = {};

    const isValid = validate();
    setIsInvoiceDataValid(isValid);
  }, [
    invoiceData,
  ]);

  function validate() {
    if (!invoiceData['entity_type']) {
      return false;
    }

    if (invoiceData['entity_type'] === CLIENT_ENTITY_TYPE.physical) {
      PHYSICAL_FIELDS.forEach(field => {
        unvalid(field.name, field.required && !invoiceData[field.name]);
      });
    }

    if (invoiceData['entity_type'] === CLIENT_ENTITY_TYPE.legal) {
      LEGAL_FIELDS.forEach(field => {
        unvalid(field.name, field.required && !invoiceData[field.name]);
      });
    }

    setUnvalidFields(unvalidFieldsRef.current);

    return Object.values(unvalidFieldsRef.current).filter(value => value).length === 0;
  }

  function unvalid(key, value) {
    unvalidFieldsRef.current = {
      ...unvalidFieldsRef.current,
      [key]: value
    };
  }

  return (
    <>
      <div className="form-group">
        <div className="form-check form-check-inline">
          <input
            id="personType1"
            className="form-check-input"
            type="radio"
            name="entity_type"
            value={CLIENT_ENTITY_TYPE.physical}
            checked={invoiceData['entity_type'] === CLIENT_ENTITY_TYPE.physical}
            onChange={onChange}
          />
          <label className="form-check-label" htmlFor="personType1">Fizinis asmuo</label>
        </div>

        <div className="form-check form-check-inline">
          <input
            id="personType2"
            className="form-check-input"
            type="radio"
            name="entity_type"
            value={CLIENT_ENTITY_TYPE.legal}
            checked={invoiceData['entity_type'] === CLIENT_ENTITY_TYPE.legal}
            onChange={onChange}
          />
          <label className="form-check-label" htmlFor="personType2">Juridinis asmuo</label>
        </div>
      </div>

      {invoiceData['entity_type'] === CLIENT_ENTITY_TYPE.physical && <>
        <div className="form-group">
          <label htmlFor="entity_name">Vardas, pavardė <span className="text-danger">*</span></label>
          <input type="text"
            id="entity_name"
            name="entity_name"
            value={invoiceData['entity_name']}
            onChange={onChange}
            className={`form-control ${isErrorsVisible && unvalidFields['entity_name'] ? 'error' : ''}`}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Adresas (gatvė, miestas, šalis) <span className="text-danger">*</span></label>
          <input type="text"
            id="address"
            name="address"
            value={invoiceData['address']}
            onChange={onChange}
            className={`form-control ${isErrorsVisible && unvalidFields['address'] ? 'error' : ''}`}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Ind. veiklos / asmens kodas <span className="text-danger">*</span></label>
          <input type="text"
            id="entity_code"
            name="entity_code"
            value={invoiceData['entity_code']}
            onChange={onChange}
            className={`form-control ${isErrorsVisible && unvalidFields['entity_code'] ? 'error' : ''}`}
          />
        </div>
        <div className="form-group">
          <label htmlFor="vat_code">PVM kodas</label>
          <input type="text"
            id="vat_code"
            name="vat_code"
            value={invoiceData['vat_code']}
            onChange={onChange}
            className="form-control"
          />
        </div>
      </>}

      {invoiceData['entity_type'] === CLIENT_ENTITY_TYPE.legal && <>
        <div className="form-group">
          <label htmlFor="entity_name">Įmonės pavadinimas <span className="text-danger">*</span></label>
          <input type="text"
            id="entity_name"
            name="entity_name"
            value={invoiceData['entity_name']}
            onChange={onChange}
            className={`form-control ${isErrorsVisible && unvalidFields['entity_name'] ? 'error' : ''}`}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Adresas (gatvė, miestas, šalis) <span className="text-danger">*</span></label>
          <input type="text"
            id="address"
            name="address"
            value={invoiceData['address']}
            onChange={onChange}
            className={`form-control ${isErrorsVisible && unvalidFields['address'] ? 'error' : ''}`}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Įmonės kodas <span className="text-danger">*</span></label>
          <input type="text"
            id="entity_code"
            name="entity_code"
            value={invoiceData['entity_code']}
            onChange={onChange}
            className={`form-control ${isErrorsVisible && unvalidFields['entity_code'] ? 'error' : ''}`}
          />
        </div>
        <div className="form-group">
          <label htmlFor="vat_code">PVM kodas</label>
          <input type="text"
            id="vat_code"
            name="vat_code"
            value={invoiceData['vat_code']}
            onChange={onChange}
            className="form-control"
          />
        </div>
      </>}
    </>
  );
}
